import {
  Container,
  Box,
  Columns,
} from "react-bulma-components";
import MyNavbar from "../../components/MyNavbar";
import {useState, useEffect} from "react";
import Clients from "./clients";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {queryClient, debug, dateFormated} from "../../utils";
import BulmaDatePicker from "../../components/BulmaDatePicker";
import {ToastContainer} from "react-toastify";


function Reception() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    document.title = "ADINE - Réception";
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MyNavbar/>
      <Container breakpoint={"fluid"}>
        <ToastContainer/>
        <Columns centered>
          <Columns.Column>
            <Box>
              <BulmaDatePicker
                label={"Date de réception"}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </Box>
          </Columns.Column>
        </Columns>
        <Clients formatedDate={dateFormated(selectedDate)}/>
      </Container>
      {debug && <ReactQueryDevtools/>}
    </QueryClientProvider>
  );
}

export default Reception;
