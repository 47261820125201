import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bulma-components";
import {toast} from "react-toastify";


const ButtonMailto = ({email, subject, body}) => {
  // Body is send to clipboard because it's buggy to send it in the mailto link (maybe because of the \n)
  return (
    <Button
      color="danger"
      title="Envoyer un email"
      onClick={(e) => {
        e.preventDefault(); // still relevant?
        window.location.href = "mailto:" + email + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent("Coller l'accusé ici");
        navigator.clipboard.writeText(body);
        toast.success("Accusé copié dans le presse-papier")
      }}
    >
      <FontAwesomeIcon icon={faEnvelope}/>
    </Button>
  );
};

export default ButtonMailto;