import * as bpac from "./bpac.js";

export async function DoPrint(samples, is_st) {
    if (bpac.IsExtensionInstalled() === false) {
        const agent = window.navigator.userAgent.toLowerCase();
        const ischrome = (agent.indexOf('chrome') !== -1) && (agent.indexOf('edge') === -1) && (agent.indexOf('opr') === -1)
        if (ischrome)
            window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank');
        return;
    }
    try {
        const objDoc = bpac.IDocument;
        // Handle printer selection
        const bpacPrinter = bpac.IPrinter;
        let instPrinter = await bpacPrinter.GetInstalledPrinters();
        let active_printer
        for (let printer of instPrinter) {
            const isOnline = await bpacPrinter.IsPrinterOnline(printer);
            console.log(printer, isOnline);
            if (isOnline) {
                objDoc.SetPrinter(printer);
                active_printer = printer;
                break;
            }
        }
        let ret
        if (is_st) {
            // const model_path = "\\\\fichiers\\Forum Echange Transit\\Utilisateurs\\LE POTTIER Francois Xavier\\label-maker\\model_app\\Etiquette_echantillons.lbx";
            // GIVE URL TO PUBLIC FOLDER
            const model_path = window.location.origin + "/label/Etiquette_echantillons.lbx";
            ret = await objDoc.Open(model_path);
        } else {
            // const model_path = "\\\\fichiers\\Forum Echange Transit\\Utilisateurs\\LE POTTIER Francois Xavier\\label-maker\\model_app\\Etiquette_echantillons_sans_code-barre.lbx";
            // GIVE URL TO PUBLIC FOLDER
            const model_path = window.location.origin + "/label/Etiquette_echantillons_sans_code-barre.lbx"
            ret = await objDoc.Open(model_path);
        };

        if (ret === true) {
            if (active_printer) objDoc.SetPrinter(active_printer);
            // const numAdlab = await objDoc.GetObject("Numéro AD-LAB");
            const numAdlab = await objDoc.GetObject("sample_nb");
            // const refClient = await objDoc.GetObject("Réf# Client");
            const refClient = await objDoc.GetObject("ref_client");
            let barCode
            if (is_st) {
                // barCode = await objDoc.GetObject("code barre");
                barCode = await objDoc.GetObject("barcode");
            }
            for (let sample of samples) {
                numAdlab.Text = sample.full_number;
                if (is_st) {
                    barCode.Text = sample.full_number;
                }
                refClient.Text = sample.reference;
                objDoc.StartPrint("", 0);
                objDoc.PrintOut(1, 0);
            }
            objDoc.EndPrint();
            objDoc.Close();
        }
    } catch (e) {
        console.log(e)
    }
}

