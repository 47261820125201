import {useQuery} from "react-query";
import {Box, Button, Form, Table, Notification, Columns, Heading} from "react-bulma-components";
import {useContext, useState} from "react";
import {DoPrint} from "../../js/brother-label-bottle.js";
import {useAxios} from "../../utils.js";
import Loader from "../../components/Loader.jsx";
import Error from "../../components/Error";
import AuthContext from "../../context/AuthContext";

function Preparation() {
  const [selectedDossiers, setSelectedDossiers] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [status, setStatus] = useState("waiting_for_preparation");
  const [selectAll, setSelectAll] = useState(false);
  const today = new Date();
  const {lab} = useContext(AuthContext);
  const api = useAxios();

  async function fetchLayers() {
    const res = await api.get(
      "/awaiting-preparations", {params: {status: status, lab_id: lab}}
    );
    setSelectedDossiers([])
    setSelectAll(false)
    return res.data;
  }

  const handleSelectedDossier = (e, dossier) => {
    if (e.target.checked) {
      if (selectedDossiers.includes(dossier)) {
        setSelectedDossiers(selectedDossiers.filter((d) => d !== dossier));
      } else {
        setSelectedDossiers([...selectedDossiers, dossier]);
      }
    } else {
      setSelectedDossiers(selectedDossiers.filter((d) => d !== dossier));
    }
  };


  const handlePrintLabels = (layers_ids) => {
    setIsPrinting(true);
    const selectedLayers = Object.entries(layers_ids).filter(([key, value]) => selectedDossiers.includes(value));
    const payload = {"selected_layers": Object.fromEntries(selectedLayers)};

    api.post("/preparation-label", payload)
      .then((response) => {
        setIsPrinting(false);
        DoPrint(response.data);
        setSelectedDossiers([]);
      })
      .catch((error) => {
        console.log(error);
        setIsPrinting(false);
      });
  };

  const handleSelectAll = (e, molp_dates) => {
    document.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      if (e.target.checked) {
        checkbox.checked = true;
        setSelectAll(true);
        setSelectedDossiers(Object.keys(molp_dates));
      } else {
        checkbox.checked = false;
        setSelectAll(false);
        setSelectedDossiers([]);
      }
    });
  }

  const {isLoading, isError, data, error} = useQuery(["folders", status, lab], fetchLayers);

  if (isLoading) return <Loader/>;

  if (isError) return <Error error={error}/>;

  if (Object.keys(data).length === 0)
    return (
      <Box>
        <p>Aucune couches n'est en attente de préparation</p>
      </Box>
    );

  if (isPrinting) {
    return (
      <>
        <Notification color="danger">Impression des étiquettes en cours...<br/>Ne pas recharger la page. La récupération
          des données peut prendre du temps.</Notification>
        <Loader/>
      </>
    );
  }

  return (
    <>
      <Form.Field>
        <Form.Control>
          <Columns centered>
            <Columns.Column size={6}>
              <Box textAlign={"center"}>
                <Columns justifyContent={"center"}>
                  <Columns.Column>
                    <Heading textAlign={"center"}>Sélection du statut</Heading>
                  </Columns.Column>
                </Columns>
                <Columns centered>
                  <Columns.Column size={6}>
                    <Button color={status === "waiting_for_preparation" ? "danger" : "info"}>
                      <Form.Radio
                        value="waiting_for_preparation"
                        name="selected_status"
                        checked={status === "waiting_for_preparation"}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        En attente de préparation
                      </Form.Radio>
                    </Button>
                  </Columns.Column>
                  <Columns.Column size={6}>
                    <Button color={status === "waiting_for_META_analysis" ? "danger" : "info"}>
                      <Form.Radio
                        value="waiting_for_META_analysis"
                        name="selected_status"
                        checked={status === "waiting_for_META_analysis"}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        En attente d'analyse META
                      </Form.Radio>
                    </Button>
                  </Columns.Column>
                </Columns>
              </Box>
            </Columns.Column>
          </Columns>
          <Columns centered>
            <Columns.Column size={6}>
              <Box textAlign={"center"}>
                {Object.keys(data.layers_ids).length > 0 ?
                  <Table style={{"textAlign": "center"}}>
                    <thead>
                    <tr>
                      <th>Numéro de dossier</th>
                      <th>Nb de couches</th>
                      <th>Date d'analyse MOLP</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(data.molp_dates).map(([dossier_nb, molp_date]) => (
                      <tr key={dossier_nb}>
                        <td>
                          <Form.Checkbox
                            defaultChecked={false}
                            onChange={(e) => handleSelectedDossier(e, dossier_nb)}
                          >{dossier_nb}</Form.Checkbox>
                        </td>
                        <td>{data.dossier_count_layers[dossier_nb]}</td>
                        {molp_date === today.toLocaleDateString() ?
                          <td style={{"color": "#f03a5f"}}>{molp_date}</td>
                          :
                          <td>{molp_date}</td>
                        }
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <Form.Checkbox
                          checked={selectAll}
                          style={{"marginTop": "20px", fontWeight: "bold"}}
                          onChange={(e) => handleSelectAll(e, data.molp_dates)}
                        >
                          Sélectionner tous les dossiers
                        </Form.Checkbox>
                      </td>
                    </tr>
                    </tbody>
                  </Table>
                  : <p>Aucun échantillon avec ce statut</p>}
              </Box>
            </Columns.Column>
          </Columns>
          <Columns centered>
            <Columns.Column size={6}>
              <Box textAlign={"center"}>
                <Button
                  color='info'
                  onClick={() => handlePrintLabels(data.layers_ids)}
                >Imprimer les étiquettes des dossiers sélectionnés
                </Button>

              </Box>
            </Columns.Column>
          </Columns>
        </Form.Control>
      </Form.Field>
    </>
  );
}

export default Preparation;
