import MyNavbar from "../../components/MyNavbar";
import {useEffect, useState} from "react";
import {Box, Button, Columns, Container, Form, Heading} from "react-bulma-components";
import {useAxios} from "../../utils";
import fileDownload from "js-file-download";
import Loader from "../../components/Loader";
import {ToastContainer} from "react-toastify";


function Aloora() {
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const api = useAxios();

  useEffect(() => {
    document.title = "ADINE - Aloora";
  }, []);

  const verifyOrder = async () => {
    setIsLoading(true)
    await api.get(`/aloora-order?order_id=${orderId}`)
      .then(res => {
        setName(res.data.name);
        setStatus(res.data.status);
      })
      .catch(err => {
        console.log(err);
      })
    setIsLoading(false)
  }

  const downloadCSV = async () => {
    setIsLoading(true)
    await api.get(`/aloora-samples?order_id=${orderId}`)
      .then(res => {
        fileDownload(res.data, `csv_export_${name || orderId}.csv`)
      })
      .catch(err => {
        console.log(err);
      })
    setIsLoading(false)
  }

  const downloadPDF = async () => {
    setIsLoading(true)
    await api.get(`/aloora-reports?order_id=${orderId}`, {responseType: 'blob'})
      .then(res => {
        fileDownload(res.data, `reports_${name || orderId}.zip`)
      })
      .catch(err => {
        console.log(err);
      })
    setIsLoading(false)
  }

  return (
    <>
      <MyNavbar/>
      <Container breakpoint={"fluid"}>
        <ToastContainer/>
        {isLoading ? <Loader message={`Téléchargement en cours ...`}/> :
          <>
            <Columns centered vCentered>
              <Columns.Column>
                <Box>
                  <Heading textAlign={"center"}>ALOORA</Heading>
                </Box>
              </Columns.Column>
            </Columns>

            <Columns centered>
              <Columns.Column size={6}>
                <Box>
                  <Columns centered style={{WebkitAlignItems: "end", textAlign: "center"}}>
                    <Columns.Column narrow>
                      <Form.Control>
                        <Form.Label textAlign={"center"}>Numéro Agrolab</Form.Label>
                        <Form.Input
                          type="text"
                          textAlign={"center"}
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                        />
                      </Form.Control>
                    </Columns.Column>
                    <Columns.Column narrow>
                      <Button color="primary" onClick={verifyOrder}>Vérifier</Button>
                    </Columns.Column>
                  </Columns>
                  {name && status &&
                    <Columns centered style={{WebkitAlignItems: "end", textAlign: "center"}}>
                      <Columns.Column narrow>
                        <p>{name} - <b>{status}</b></p>
                      </Columns.Column>
                      <Columns.Column narrow>
                        {status === "Terminé" && <Button color="primary" onClick={downloadCSV}>Télécharger CSV</Button>}
                      </Columns.Column>
                      <Columns.Column narrow>
                        {status === "Terminé" && <Button color="primary" onClick={downloadPDF}>Télécharger PDF</Button>}
                      </Columns.Column>
                    </Columns>
                  }

                </Box>
              </Columns.Column>
            </Columns>
          </>
        }
      </Container>
    </>
  )
}

export default Aloora;