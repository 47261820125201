import {
  Container,
} from "react-bulma-components";
import MyNavbar from "../../components/MyNavbar";
import {useEffect} from "react";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import Preparation from "./preparation";
import {queryClient} from "../../utils";
import {debug} from "../../utils";
import {ToastContainer} from "react-toastify";


function Laboratory() {

  useEffect(() => {
    document.title = "ADINE - Laboratoire";
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MyNavbar/>
      <Container breakpoint={"fluid"}>
        <ToastContainer/>
        <Preparation/>
      </Container>
      {debug && <ReactQueryDevtools/>}
    </QueryClientProvider>
  );
}

export default Laboratory;
