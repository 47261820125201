import {Columns, Content, Heading, Image, Box} from 'react-bulma-components'

function Error({error}) {
  return (
    <Box>
      <Columns vCentered>
        <Columns.Column>
          <Heading size={1} textAlign={"center"}>Erreur</Heading>
          <Content textAlign={"center"}>Veuillez contacter le service informatique</Content>
          <Content textAlign={"center"}>{error}</Content>
        </Columns.Column>
        <Columns.Column alignItems={"center"}>
          <Image src={"media/error.jpg"}/>
        </Columns.Column>
      </Columns>
    </Box>
  )
    ;
}

export default Error;
