import {Box, Form, Button, Container, Columns, Notification, Image, Heading} from "react-bulma-components";
import {useState} from "react";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {ToastContainer} from "react-toastify";

function Login() {
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const {loginUser} = useContext(AuthContext);

  const handleSubmit = e => {
    e.preventDefault();
    username.length > 0 && loginUser(username, pwd);
  };

  return (
    <Container
      style={{width: "500px", paddingTop: "100px"}}
    >
      <ToastContainer/>
      <Box>
        <Columns centered>
          <Columns.Column narrow>
            <Image
              src={"media/logo-ad-lab.jpg"}/>
          </Columns.Column>
        </Columns>
        <Columns centered>
          <Columns.Column narrow>
            <Image src={"media/plus.png"} size={96}/>
          </Columns.Column>
          <Columns.Column textAlign={"center"}>
            <Notification color="info" light>
              <Heading size={1}>ADINE</Heading>
            </Notification>
          </Columns.Column>
        </Columns>
        <Columns centered>
          <Columns.Column></Columns.Column>
        </Columns>
        <Columns centered>
          <Columns.Column>
            <form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Control>
                  <Columns textAlign={"center"} centered>
                    <Columns.Column>
                      <Form.Input
                        color="info"
                        placeholder="Login"
                        required={true}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </Columns.Column>
                  </Columns>
                  <Columns>
                    <Columns.Column>
                      <Form.Input
                        color="info"
                        placeholder="Mot de passe"
                        required={true}
                        type="password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                      />
                    </Columns.Column>
                  </Columns>
                  <Columns centered>
                    <Columns.Column narrow>
                      <Button color="danger">
                        Connexion
                      </Button>
                    </Columns.Column>
                  </Columns>
                </Form.Control>
              </Form.Field>
            </form>
          </Columns.Column>
        </Columns>
      </Box>
    </Container>
  );
}

export default Login;
