import {Box, Button, Notification, Table, Columns, Form} from "react-bulma-components";
import {useQuery} from "react-query";
import {useContext, useState} from "react";
import {DoPrint} from "../../js/brother-label-sample.js";
import {useAxios} from "../../utils.js";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import {toast} from "react-toastify";
import AuthContext from "../../context/AuthContext";


function LabelSample({formatedDate, selectedAccount}) {

  const [isPrinting, setIsPrinting] = useState(false);
  const {lab} = useContext(AuthContext);
  const api = useAxios();

  async function fetchFolders(account_id, date) {
    const res = await api.get("/dossiers", {
      params: {
        site__account__id: account_id,
        reception_date: date,
        archived_at__isnull: true,
        page_size: 1000,
        site__lab__id: lab
      }
    });
    return res.data.results;
  }

  const createSamples = (nb) => {
    let samples = [<option value="-" key="-">-</option>];
    for (var i = 1; i < nb + 1; i++) {
      samples.push(<option value={i} key={i}>{i}</option>)
    }
    return samples;
  }

  const handlePrint = (folder_id, is_st) => {
    setIsPrinting(true);
    api.get("/samples", {params: {dossier__id: folder_id, page_size: 500}})
      .then((response) => {
        DoPrint(response.data.results, is_st);
        setIsPrinting(false);
      });
  };

  const handlePrintOneSample = (folder_id, is_st, selectSample) => {
    setIsPrinting(true);
    if (selectSample === "-") {
      setIsPrinting(false);
      toast.error("Veuillez sélectionner un échantillon");
      return;
    }
    api.get("/samples", {params: {dossier__id: folder_id, number: selectSample}})
      .then((response) => {
        DoPrint(response.data.results, is_st);
        setIsPrinting(false);
      });
  };

  const dossierQuery = useQuery(
    ["folders", selectedAccount, formatedDate],
    () => fetchFolders(selectedAccount[0], formatedDate)
  );

  if (dossierQuery.isLoading) return <Loader/>;

  if (dossierQuery.isError) return <Error error={dossierQuery.error}/>;

  return (
    <Box style={{alignContent: "center", alignItems: "center"}}>
      <Notification color="info" style={{textAlign: "center"}}>
        Dossiers pour {selectedAccount[1]} le{" "}
        {new Date(formatedDate).toLocaleDateString()}
      </Notification>
      <Columns centered vCentered>
        <Columns.Column narrow>
          <Table style={{textAlign: "center"}}>
            <thead>
            <tr>
              <th>Dossier</th>
              <th>Nb d'échantillons</th>
              <th>Sous-traitance</th>
              <th>Sélectionner échantillon</th>
              <th>Étiquettes</th>
            </tr>
            </thead>
            <tbody>
            {dossierQuery.data.map((folder) => (
              <tr key={folder.id}>
                <td>{folder.number}</td>
                <td>{folder.nb_samples_unarchived}</td>
                <td>{folder.is_st ? "Oui" : "Non"}</td>
                <td>
                  <Form.Field>
                    <Form.Control>
                      <Form.Select id={"selector-" + folder.id}>
                        {createSamples(folder.nb_samples_unarchived)}
                      </Form.Select>
                      <Button
                        color="info"
                        style={{marginLeft: "10px"}}
                        disabled={isPrinting}
                        onClick={() => handlePrintOneSample(folder.id, folder.is_st, document.getElementById("selector-" + folder.id).value)}
                      >Imprimer l'échantillon</Button>
                    </Form.Control>
                  </Form.Field>
                </td>
                <td>
                  <Button
                    id={folder.id}
                    color="primary"
                    disabled={isPrinting}
                    onClick={() => {
                      handlePrint(folder.id, folder.is_st);
                    }}
                  >
                    Imprimer le dossier
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Columns.Column>
      </Columns>
      {isPrinting && <Notification color="danger">Impression des étiquettes en cours...</Notification>}
    </Box>
  );
}

export default LabelSample;
