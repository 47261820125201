import {useQuery} from "react-query"
import {
  Box,
  Columns,
  Dropdown,
} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import Accuse from "./accuse";
import {useContext, useEffect, useState} from "react";
import LabelSample from "./label-sample";
import {useAxios} from "../../utils";
import Subcontracting from "./subcontracting";
import Loader from "../../components/Loader.jsx"
import Error from "../../components/Error.jsx"
import AuthContext from "../../context/AuthContext";

function Clients({formatedDate}) {

  const [selectedAccount, setSelectedAccount] = useState("");
  const [showAccuse, setShowAccuse] = useState(false);
  const [showLabel, setShowlabel] = useState(false);
  const [showST, setShowST] = useState(false);
  const api = useAxios();
  const {lab} = useContext(AuthContext);

  async function fetchClients(date) {
    const res = await api.get("/reception-accounts", {params: {reception_date: date, lab_id: lab}});
    return res.data.accounts;
  }

  const handleSelection = (account) => {
    setShowAccuse(false);
    setShowlabel(false);
    setShowST(false);
    if (account[0] === 'accuse') {
      setShowAccuse(true);
      setSelectedAccount([account[1], account[2]]);
      return
    }
    if (account[0] === 'label') {
      setShowlabel(true);
      setSelectedAccount([account[1], account[2]]);
      return
    }
    if (account[0] === 'st') {
      setShowST(true);
      setSelectedAccount([account[1], account[2]]);
      return
    }
  }


  useEffect(() => {
    setShowAccuse(false);
    setShowlabel(false);
    setShowST(false);
    setSelectedAccount("");
  }, [formatedDate, lab]);

  const {isLoading, isError, data, error} = useQuery(
    ['clients', formatedDate, lab],
    () => fetchClients(formatedDate),
    {refetchOnWindowFocus: false}
  );

  if (isLoading) return <Loader/>

  if (isError) return <Error error={error}/>;

  if (Object.keys(data).length === 0) return <Box><p>Pas de compte en réception
    le {new Date(formatedDate).toLocaleDateString()}</p></Box>

  return (
    <>
      <Columns>
        <Columns.Column narrow>
          <Box>
            {Object.entries(data).map((account) => (
              <Columns key={account[0]}>
                <Columns.Column narrow>
                  <Dropdown
                    label={account[1]}
                    id={account[0]}
                    onChange={handleSelection}
                    color={selectedAccount[0] === account[0] ? "info" : "success"}
                    icon={<FontAwesomeIcon icon={faAngleDown} style={{marginLeft: "10px"}} size="sm"/>}
                  >
                    <Dropdown.Item
                      renderAs="a"
                      value={["accuse", account[0], account[1]]}
                    >
                      Accusé
                    </Dropdown.Item>
                    <Dropdown.Item
                      renderAs="a"
                      value={["label", account[0], account[1]]}
                    >
                      Étiquette
                    </Dropdown.Item>
                    <Dropdown.Item
                      renderAs="a"
                      value={["st", account[0], account[1]]}
                    >
                      Sous-traitance
                    </Dropdown.Item>
                  </Dropdown>
                </Columns.Column>
              </Columns>
            ))}
          </Box>
        </Columns.Column>
        <Columns.Column>
          {showAccuse && <Accuse selectedAccount={selectedAccount} formatedDate={formatedDate}/>}
          {showLabel && <LabelSample selectedAccount={selectedAccount} formatedDate={formatedDate}/>}
          {showST && <Subcontracting selectedAccount={selectedAccount} formatedDate={formatedDate}/>}
        </Columns.Column>
      </Columns>
    </>
  );
}

export default Clients;