import {createContext, useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../utils";
import {toast} from "react-toastify";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (username, password) => {
    const response = await fetch(API_URL + "/token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      navigate("/");
    } else {
      toast.error("Login ou mot de passe incorrect");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
  };

  const [lab, setLab] = useState(() => {
      if (localStorage.getItem("selectedLab")) {
        return localStorage.getItem("selectedLab");
      } else if (user !== null && user.profile.lab.length !== 0) {
        return user.profile.lab[0].id;
      } else {
        return null;
      }
    }
  );

  const contextData = {
    user,
    setUser,
    lab,
    setLab,
    authTokens,
    setAuthTokens,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    if (authTokens !== null) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};