import "./App.css";
import {
  Block,
  Columns,
  Container,
} from "react-bulma-components";
import MyNavbar from "./components/MyNavbar";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {useContext} from "react";
import {queryClient} from "./utils";
import {debug} from "./utils";
import AuthContext from "./context/AuthContext";
import CardMenu from "./components/CardMenu";


function App() {

  const {user} = useContext(AuthContext);

  return (
    <QueryClientProvider client={queryClient}>
      <MyNavbar/>
      <Container breakpoint={"fluid"}>
        <Block>
          <Columns>
            <Columns.Column size={4}>
              <CardMenu path={'/reception'} imagePath={"media/reception.png"} title={'RÉCEPTION'}/>
            </Columns.Column>
            <Columns.Column size={4}>
              <CardMenu path={'/laboratory'} imagePath={"media/observation.png"} title={'LABORATOIRE'}/>
            </Columns.Column>
            {user.groups && user.groups.includes("Manager") &&
              <Columns.Column size={4}>
                <CardMenu path={'/export'} imagePath={"media/share.png"} title={'EXPORTS'}/>
              </Columns.Column>
            }
            <Columns.Column size={4}>
              <CardMenu path={'/aloora'} imagePath={"media/letter-a.png"} title={'ALOORA'}/>
            </Columns.Column>
          </Columns>
        </Block>
      </Container>
     {debug && <ReactQueryDevtools/>}
    </QueryClientProvider>
  );
}

export default App;
