import {Box, Columns, Heading, Image} from "react-bulma-components";
import {Link} from "react-router-dom";


function CardMenu({ path, title, imagePath }) {
  return (
    <Box>
      <Link to={path}>
        <Columns centered vCentered textAlign={"center"}>
          <Columns.Column size={4}>
            <Image src={imagePath} />
          </Columns.Column>
          <Columns.Column size={8}>
            <Heading>{title}</Heading>
          </Columns.Column>
        </Columns>
      </Link>
    </Box>
  );
}

export default CardMenu;

