import {useEffect, useState} from "react";
import {Form} from "react-bulma-components";

function SelectWeek({week, setWeek}) {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const weeks = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(i);
    }
    setWeeks(weeks);
  }, []);

  return (
    <Form.Control>
      <Form.Label textAlign={"center"}>Semaine</Form.Label>
      <Form.Select
        className="select-week"
        value={week}
        onChange={(e) => setWeek(e.target.value)}
      >
        {weeks.map((w) => (
          <option key={w} value={w}>
            {w}
          </option>
        ))}
      </Form.Select>
    </Form.Control>
  );
}

export default SelectWeek;