import "../styles/Loader.css"
import {Columns, Form} from "react-bulma-components";

function BulmaDatePicker({label, selectedDate, setSelectedDate}) {

  const handleDateFormat = (date) => {
    try {
      return date.toISOString().split('T')[0]
    } catch (error) {
      return date
    }
  }

  return (
    <Form.Field>
      <Form.Control>
        <Columns centered>
          <Columns.Column narrow={true}>
            <Form.Label textAlign={"center"}>{label}</Form.Label>
            <Form.Input
              type={"date"}
              value={handleDateFormat(selectedDate)}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
            />
          </Columns.Column>
        </Columns>
      </Form.Control>
    </Form.Field>
  );
}

export default BulmaDatePicker;