import "../styles/Loader.css"
import {Box, Columns, Notification} from "react-bulma-components";

function Loader({message}) {
  return (
    <Columns centered>
      <Columns.Column size={8}>
        <Box className="box-loader">
          <div className="bars"></div>
          {message && <Notification color="warning" light>{message}</Notification>}
        </Box>
      </Columns.Column>
    </Columns>
  );
}

export default Loader;