import {useQuery} from "react-query";
import {
  Box,
  Notification,
  Form,
  Table,
  Columns,
  Button,
} from "react-bulma-components";
import {useContext, useState} from "react";
import fileDownload from "js-file-download";
import {useAxios} from "../../utils";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import {toast} from "react-toastify";
import AuthContext from "../../context/AuthContext";


function Subcontracting({selectedAccount, formatedDate}) {
  const [selectedSTs, setSelectedSTs] = useState([]);
  const [selectedDossiers, setSelectedDossiers] = useState([]);
  const [subcontractor, setSubcontractor] = useState("Agrolab");
  const [reportType, setReportType] = useState("Par dossier");
  const [selectAll, setSelectAll] = useState(false);
  const {lab} = useContext(AuthContext);
  const api = useAxios();

  async function fetchSamples(account_id, date) {
    setSelectedSTs([]);
    setSelectedDossiers([]);
    setSelectAll(false)
    const res = await api.get(
      "/samples-st", {params: {account_id: account_id, reception_date: date, lab_id: lab}}
    );
    return res.data.samples;
  }

  async function fetchSubcontractors() {
    const res = await api.get("/subcontractors");
    return res.data;
  }

  const handleSelectedST = (e, st) => {
    if (e.target.checked) {
      if (selectedSTs.includes(st)) {
        setSelectedSTs(selectedSTs.filter((s) => s !== st));
      } else {
        setSelectedSTs([...selectedSTs, st]);
      }
    } else {
      setSelectedSTs(selectedSTs.filter((s) => s !== st));
    }
  };

  const handleSelectedDossier = (e, dossier) => {
    if (e.target.checked) {
      if (selectedDossiers.includes(dossier)) {
        setSelectedDossiers(selectedDossiers.filter((d) => d !== dossier));
      } else {
        setSelectedDossiers([...selectedDossiers, dossier]);
      }
    } else {
      setSelectedDossiers(selectedDossiers.filter((d) => d !== dossier));
    }
  };

  const handleDownload = (samples) => {
    const data = {
      samples: samples,
      selected_dossier: selectedDossiers,
      selected_sts: selectedSTs,
      subcontractor: subcontractor,
      report_type: reportType,
    };
    api.post("/export-st", data, {responseType: "blob"})
      .then((response) => {
        if (response.headers['content-type'] === 'application/json') {
          response.data.text().then((text) => toast.error(text))
          return
        }

        fileDownload(
          response.data,
          `ST_${subcontractor}_${new Date(formatedDate).toLocaleDateString()}${response.headers['content-type'] === 'application/zip' ? '.zip' : '.xlsx'}`
        );
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const handleSelectAll = (e, data) => {
    document.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      if (e.target.checked) {
        checkbox.checked = true;
        setSelectAll(true);
        setSelectedDossiers(data[2]);
        setSelectedSTs(data[1]);
      } else {
        checkbox.checked = false;
        setSelectAll(false);
        setSelectedDossiers([]);
        setSelectedSTs([]);
      }
    });
  }

  const handleClipboard = (samples) => {
    const data = {
      samples: samples,
      selected_dossier: selectedDossiers,
      selected_sts: selectedSTs,
      subcontractor: subcontractor,
      report_type: reportType,
    };

    if (selectedSTs.length === 0) {
      toast.warn("Veuillez sélectionner au moins un type de ST");
      return;
    }

    if (selectedDossiers.length === 0) {
      toast.warn("Veuillez sélectionner au moins un dossier");
      return;
    }

    api.post("/row-st", data)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
          return;
        }
        navigator.clipboard.writeText(response.data.join("\t"));
        toast.success("Copié dans le presse-papier");
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const {isLoading, isError, data, error} = useQuery(
    ["st-samples", selectedAccount, formatedDate],
    () => fetchSamples(selectedAccount[0], formatedDate),
    {refetchOnWindowFocus: false}
  );
  const querySubcontractors = useQuery("subcontractors", () =>
      fetchSubcontractors(),
    {refetchOnWindowFocus: false}
  );

  if (isLoading) return <Loader/>;

  if (isError) return <Error error={error}/>;

  return (
    <Box>
      <Notification
        style={{textAlign: "center"}}
        color={data[1].length === 0 ? "danger" : "info"}
      >
        {data[1].length === 0
          ? "Aucun dossier sous-traité"
          : "Sous-traitance pour " +
          selectedAccount[1] +
          " le " +
          new Date(formatedDate).toLocaleDateString()}
      </Notification>
      {data[1].length !== 0 &&
        <Form.Field>
          <Form.Control>
            <Columns centered>
              <Columns.Column narrow>
                <Table>
                  <thead>
                  <tr>
                    <th>Dossiers</th>
                  </tr>
                  </thead>
                  <tbody>
                  {data[2].map((dossier) => (
                    <tr key={dossier}>
                      <td>
                        <Form.Checkbox
                          defaultChecked={false}
                          onChange={(e) => handleSelectedDossier(e, dossier)}
                        >
                          {dossier}
                        </Form.Checkbox>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Columns.Column>
              <Columns.Column narrow>
                <Table>
                  <thead>
                  <tr>
                    <th>Types de ST</th>
                  </tr>
                  </thead>
                  <tbody>
                  {data[1].map((st) => (
                    <tr key={st}>
                      <td>
                        <Form.Checkbox
                          defaultChecked={false}
                          onChange={(e) => handleSelectedST(e, st)}
                        >
                          {st}
                        </Form.Checkbox>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Columns.Column>
              <Columns.Column narrow>
                <Form.Label>Sous-traitant</Form.Label>
                <Form.Select
                  onChange={(e) => setSubcontractor(e.target.value)}
                  defaultValue={subcontractor}
                >
                  {querySubcontractors.isSuccess &&
                    querySubcontractors.data.map((sub) => (
                      <option value={sub.name} key={sub.id}>
                        {sub.name}
                      </option>
                    ))}
                </Form.Select>
              </Columns.Column>
              <Columns.Column narrow>
                <Form.Label>Type de rapport</Form.Label>
                <Form.Select
                  onChange={(e) => setReportType(e.target.value)}
                  defaultValue={reportType}
                >
                  <option value="Par dossier">Par dossier</option>
                  <option value="Par échantillon">Par échantillon</option>
                </Form.Select>
              </Columns.Column>

            </Columns>
            <Columns centered>
              <Columns.Column narrow>
                <Form.Checkbox
                  checked={selectAll}
                  style={{fontWeight: "bold"}}
                  onChange={(e) => handleSelectAll(e, data)}
                >
                  Sélectionner tout
                </Form.Checkbox>
              </Columns.Column>
            </Columns>

            <Columns centered>
              <Columns.Column narrow>
                <Button color={"info"} onClick={() => handleDownload(data[0])}>
                  Télécharger la sélection
                </Button>
              </Columns.Column>
              <Columns.Column narrow>
                <Button color="warning" onClick={() => handleClipboard(data[0])}>
                  Copier la ligne ST
                </Button>
              </Columns.Column>
            </Columns>
          </Form.Control>
        </Form.Field>
      }
    </Box>
  );
}

export default Subcontracting;
