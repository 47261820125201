import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "bulma/css/bulma.min.css";
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reception from "./routes/reception/reception"
import Laboratory from "./routes/laboratory/laboratory"
import Login from "./routes/login/login.jsx"
import { AuthProvider } from "./context/AuthContext";
import { PrivateRoute } from "./utils";
import Export from "./routes/export/export";
import Aloora from "./routes/agrolab/Agrolab";


ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoute />} path="/">
            <Route element={<App />} path="/" />
          </Route>
          <Route element={<PrivateRoute />} path="/reception">
            <Route element={<Reception />} path="/reception" />
          </Route>
          <Route element={<PrivateRoute />} path="/laboratory">
            <Route element={<Laboratory />} path="/laboratory" />
          </Route>
          <Route element={<PrivateRoute />} path="/export">
            <Route element={<Export />} path="/export" />
          </Route>
          <Route element={<PrivateRoute />} path="/aloora">
            <Route element={<Aloora />} path="/aloora" />
          </Route>
          <Route element={<Login />} path="/login" />
        </Routes>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
