import {Form} from "react-bulma-components";
import {useContext, useEffect} from "react";
import AuthContext from "../context/AuthContext";

function LabSelector() {

  const {user, lab, setLab} = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem("selectedLab", lab);
  }, [lab]);

  return (
    <Form.Select
      onChange={(e) => setLab(e.target.value)}
      value={lab}
    >
      {user.profile.lab.map((labo) => (
        <option key={labo.id} value={labo.id}>{labo.name}</option>
      ))}
    </Form.Select>

  );
}

export default LabSelector;

