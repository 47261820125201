import axios from "axios";
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {QueryClient} from "react-query";
import jwt_decode from "jwt-decode";
import {useContext} from "react";
import AuthContext from "./context/AuthContext";


let API_URL = "http://localhost:8000/api";
if (window.location.origin === "http://localhost:3000") {
  API_URL = "http://localhost:8000/api";
} else {
  API_URL = window.location.origin + "/api"
}
export {API_URL};

export const useAxios = () => {
  const {authTokens, setUser, setAuthTokens} = useContext(AuthContext);
  const navigate = useNavigate();
  let retry = 0;

  const axiosInstance = axios.create({
    baseURL: API_URL + "/lims",
    headers: {Authorization: `Bearer ${authTokens !== null ? authTokens.access : ''}`}
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      // NO REFRESH TOKEN
      if (authTokens === null || !authTokens.refresh) {
        navigate("/login")
        return Promise.reject(error);
      }
      if (error.response.status === 401 && retry < 2) {
        retry += 1;
        return axiosInstance.post(API_URL + "/token/refresh/", {
          refresh: authTokens.refresh
        })
          .then(res => {
            if (res.status === 200) {
              setAuthTokens(res.data);
              setUser(jwt_decode(res.data.access));
              localStorage.setItem("authTokens", JSON.stringify(res.data));
              axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + authTokens.access;
              originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access;
              return axiosInstance(originalRequest);
            }
          })
        // OLD REFRESH TOKEN
      } else if (error.response.status === 401 && retry >= 2) {
        navigate("/login")
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  )
  return axiosInstance;
};

export const PrivateRoute = () => {
  const {user, authTokens} = useContext(AuthContext);
  if (!user) return <Navigate to="/login"/>;
  if (!authTokens) return <Navigate to="/login"/>;
  const exp_refresh_token = authTokens.refresh ? jwt_decode(authTokens.refresh).exp : null;
  if (exp_refresh_token <= Date.now() / 1000) return <Navigate to="/login"/>;
  return <Outlet/>;
};

export const IsLoggedIn = () => {
  return localStorage.getItem("token") !== null;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {retry: 0},
  }
});

export const debug = window.location.origin === "http://localhost:3000";

export const dateFormated = (date) => {
  let formatedDate;
  try {
    formatedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  } catch (error) {
    formatedDate = date
  }
  return (
    formatedDate
  );
};

export const getDatesForWeek = (weekNumber, year) => {
  // Calculate the start and end date of the week
  const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const endDate = new Date(year, 0, 1 + (weekNumber - 1) * 7 + 6);

  // Create an array to store the daily dates
  const dates = [];

  // Loop through all dates within the week and add them to the array
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(dateFormated(new Date(currentDate)));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Return the array of daily dates
  return dates;
}

export const getWeekNumber = (date) => {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}