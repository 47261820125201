import {useEffect, useState} from "react";
import {Form} from "react-bulma-components";

function SelectYear({year, setYear}) {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 2017; i--) {
      years.push(i);
    }
    setYears(years);
  }, []);

  return (
    <Form.Control>
      <Form.Label textAlign={"center"}>Année</Form.Label>
      <Form.Select
        value={year}
        onChange={(e) => {
          setYear(e.target.value);
        }}
      >
        {years.map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </Form.Select>
    </Form.Control>
  );

}

export default SelectYear;