import * as bpac from "./bpac.js";

export async function DoPrint(preparations) {
    if (bpac.IsExtensionInstalled() === false) {
        const agent = window.navigator.userAgent.toLowerCase();
        const ischrome = (agent.indexOf('chrome') !== -1) && (agent.indexOf('edge') === -1) && (agent.indexOf('opr') === -1)
        if (ischrome)
            window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank');
        return;
    }
    try {
        const objDoc = bpac.IDocument;
        // Handle printer selection
        const bpacPrinter = bpac.IPrinter;
        let instPrinter = await bpacPrinter.GetInstalledPrinters();
        let active_printer
        for (let printer of instPrinter) {
            const isOnline = await bpacPrinter.IsPrinterOnline(printer);
            console.log(printer, isOnline);
            if (isOnline) {
                objDoc.SetPrinter(printer);
                active_printer = printer;
                break;
            }
        }
        // const model_path = "\\\\fichiers\\Forum Echange Transit\\Utilisateurs\\LE POTTIER Francois Xavier\\label-maker\\model_app\\modele_bouchon_flacon_line_split.lbx";
        // GIVE URL TO PUBLIC FOLDER
        const model_path = window.location.origin + "/label/modele_bouchon_flacon_line_split.lbx";

        const ret = await objDoc.Open(model_path);
        if (ret === true) {
            if (active_printer) objDoc.SetPrinter(active_printer);
            const labYear = await objDoc.GetObject("lab_year");
            const compNb = await objDoc.GetObject("comp_nb");
            const beackerNb = await objDoc.GetObject("beacker_nb");
            const mg = await objDoc.GetObject("MG");
            for (let preparation of preparations) {
                labYear.Text = preparation.sample_nb.substring(0, 6);
                compNb.Text = preparation.sample_nb.substring(6);
                // console.log(preparation.sample_nb.substring(0, 6));
                // console.log(preparation.sample_nb.substring(6));
                beackerNb.Text = preparation.beacker_nb;
                mg.Text = preparation.MG_or_prep;
                objDoc.StartPrint("", 0);
                objDoc.PrintOut(2, 0);
            }
            objDoc.EndPrint();
            objDoc.Close();
        }
    } catch (e) {
        console.log(e)
    }
}
