import ".././styles/MyNavbar.css";
import {Navbar, Button} from "react-bulma-components";
import {Link} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import LabSelector from "./LabSelector";


function MyNavbar() {

  const {user, logoutUser} = useContext(AuthContext);

  return (
    <Navbar pl={4} mb={5} className="navbar">
      <Navbar.Brand>
        <Link to={"/"}>
          <img src={"media/logo-ad-lab.jpg"} className="logo" alt="ad-lab"/>
        </Link>
      </Navbar.Brand>
      <Navbar.Container align="left">
        <Navbar.Item renderAs="p">
          <Link to="/reception">RÉCEPTION</Link>
        </Navbar.Item>
        <Navbar.Item renderAs="p">
          <Link to="/laboratory">LABORATOIRE</Link>
        </Navbar.Item>
        {user.groups && user.groups.includes("Manager") &&
          <Navbar.Item renderAs="p">
            <Link to="/export">EXPORTS</Link>
          </Navbar.Item>
        }
        <Navbar.Item renderAs="p">
          <Link to="/aloora">ALOORA</Link>
        </Navbar.Item>
      </Navbar.Container>
      <Navbar.Container align="right">
        <Navbar.Item renderAs="div">
          <LabSelector/>
        </Navbar.Item>
        <Navbar.Item renderAs="p">
          <Button color="danger" onClick={logoutUser}>Déconnexion</Button>
        </Navbar.Item>
      </Navbar.Container>
    </Navbar>
  );
}

export default MyNavbar;
