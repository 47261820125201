import {
  Notification,
  Box,
  Columns,
  Button,
  Form
} from "react-bulma-components";
import {useQuery} from "react-query"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {useAxios} from "../../utils";
import Loader from "../../components/Loader.jsx"
import Error from "../../components/Error.jsx"
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import ButtonMailto from "../../components/MailTo";
import {toast} from "react-toastify";
import React, {useContext, useState, useEffect} from "react";
import AuthContext from "../../context/AuthContext";

function Accuse({formatedDate, selectedAccount}) {

  const {
    isLoading,
    isError,
    data,
    error
  } = useQuery(['accuse', selectedAccount, formatedDate], () => fetchAccuse(selectedAccount[0], formatedDate));

  const api = useAxios();
  const {lab} = useContext(AuthContext);
  const [enoughMater, setEnoughMater] = useState(false)
  const [accuse, setAccuse] = useState("")
  const notEnough = "\nCertains des échantillons reçus présentent une quantité de matière inférieure à celle prévue pour la réalisation de l'ensemble des essais (cf. votre offre commerciale); Ceci induit un risque de ne pas pouvoir rendre un résultat pour certains des échantillons. Ces échantillons ne pourront pas non plus faire l’objet d’une contre-analyse en cas de besoin."

  useEffect(() => {
    if (data !== undefined) {
      if (enoughMater) {
        setAccuse(data.accuse.substring(0, data.accuse.length - 24) + notEnough + data.accuse.substring(data.accuse.length - 24))
      } else {
        setAccuse(data.accuse)
      }
    }
  }, [enoughMater, data]);

  async function fetchAccuse(account_id, date) {
    const res = await api.get("/accuse", {params: {account_id: account_id, reception_date: date, lab_id: lab}});
    return res.data;
  }


  if (isLoading) return <Loader/>

  if (isError) return <Error error={error}/>

  return (
    <Box>
      <Notification color="info" textAlign={"center"}>
        Accusé de réception pour {selectedAccount[1]} le {new Date(formatedDate).toLocaleDateString()}
      </Notification>
      <Columns centered vCentered textAlign={"center"}>
        <Columns.Column size={8}>
          <Form.Label>Un ou plusieurs échantillons comportent moins de matière que prévue dans l'offre commerciale pour l'analyse hors-amiante :</Form.Label>
        </Columns.Column>
        <Columns.Column size={1}>
          <Form.Checkbox
            value={enoughMater}
            onChange={() => setEnoughMater(!enoughMater)}
          />
        </Columns.Column>
      </Columns>
      <Columns centered vCentered textAlign={"center"}>
        <Columns.Column size={8}>
          <Form.Textarea
            value={accuse}
            readOnly={true}
            style={{height: "400px"}}
          />
        </Columns.Column>
      </Columns>
      <Columns centered textAlign={"center"}>
        <Columns.Column size={1}>
          <CopyToClipboard
            text={accuse}
            onCopy={() => toast.success("Accusé copié dans le presse-papier")}
            options={{"format": "text/plain"}}
          >
            <Button color="danger">
              <FontAwesomeIcon icon={faCopy}/>
            </Button>
          </CopyToClipboard>
        </Columns.Column>
        <Columns.Column size={1}>
          <ButtonMailto
            email={data.emails}
            subject={`Accusé de réception - AD-LAB`}
            body={data.accuse}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
}

export default Accuse;