import MyNavbar from "../../components/MyNavbar";
import {useEffect, useState} from "react";
import {Box, Button, Columns, Container, Heading} from "react-bulma-components";
import {getDatesForWeek, getWeekNumber, useAxios} from "../../utils";
import fileDownload from "js-file-download";
import Loader from "../../components/Loader";
import SelectYear from "../../components/SelectYear";
import SelectWeek from "../../components/SelectWeek";
import {ToastContainer} from "react-toastify";


function Export() {
  const [redropYear, setRedropYear] = useState(new Date().getFullYear().toString());
  const [redropWeek, setRedropWeek] = useState(getWeekNumber(new Date()) - 1);
  const [gridYear, setGridYear] = useState(new Date().getFullYear().toString());
  const [gridWeek, setGridWeek] = useState(getWeekNumber(new Date()) - 1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const api = useAxios();

  useEffect(() => {
    document.title = "ADINE - Exports";
  }, []);


  const handleDownloadRedrop = async () => {
    setLoadingMessage(`Téléchargement des redrops de la semaine ${redropWeek} de l'année ${redropYear}...`)
    setIsLoading(true)

    await api.get(`/redrop-stats-csv?year=${redropYear}&week=${redropWeek}`)
      .then(res => {
        fileDownload(res.data, `redrop-stats-${redropYear}-W${redropWeek}.csv`)
      })
      .catch(err => {
        console.log(err);
      })
    setIsLoading(false)
  }
  const handleDownloadGrid = async () => {
    setIsLoading(true)
    const weekDates = getDatesForWeek(gridWeek, gridYear);
    for await (const date of weekDates) {
      setLoadingMessage(`Téléchargement des grilles du ${date} => ${Math.round((weekDates.indexOf(date) + 1) / weekDates.length * 100)} %`)
      await api.get(`/gridspots-stats-csv?meta_analysis_date=${date}`)
        .then(res => {
          fileDownload(res.data, `gridspots-stats-${date}.csv`)
        })
        .catch(err => {
          console.log(err);
        });
    }
    setIsLoading(false)
  }

  return (
    <>
      <MyNavbar/>
      <Container breakpoint={"fluid"}>
        <ToastContainer/>
        {isLoading ? <Loader message={loadingMessage}/> :
          <>
            <Columns centered vCentered>
              <Columns.Column>
                <Box>
                  <Heading textAlign={"center"}>Exports</Heading>
                </Box>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Box>
                  <Columns centered style={{textAlign: "center"}}>
                    <Columns.Column>
                      <Heading>Redrops</Heading>
                    </Columns.Column>
                  </Columns>
                  <Columns centered style={{WebkitAlignItems: "end", textAlign: "center"}}>
                    <Columns.Column narrow>
                      <SelectYear year={redropYear} setYear={setRedropYear}/>
                    </Columns.Column>
                    <Columns.Column narrow>
                      <SelectWeek week={redropWeek} setWeek={setRedropWeek}/>
                    </Columns.Column>
                    <Columns.Column narrow>
                      <Button color="primary" onClick={handleDownloadRedrop}>Télécharger</Button>
                    </Columns.Column>
                  </Columns>
                </Box>
              </Columns.Column>
              <Columns.Column>
                <Box>
                  <Columns centered style={{textAlign: "center"}}>
                    <Columns.Column>
                      <Heading>Grilles</Heading>
                    </Columns.Column>
                  </Columns>
                  <Columns centered style={{WebkitAlignItems: "end", textAlign: "center"}}>
                    <Columns.Column narrow>
                      <SelectYear year={gridYear} setYear={setGridYear}/>
                    </Columns.Column>
                    <Columns.Column narrow>
                      <SelectWeek week={gridWeek} setWeek={setGridWeek}/>
                    </Columns.Column>
                    <Columns.Column narrow>
                      <Button color="primary" onClick={handleDownloadGrid}>Télécharger</Button>
                    </Columns.Column>
                  </Columns>
                </Box>
              </Columns.Column>
            </Columns>
          </>
        }
      </Container>
    </>
  )
}

export default Export;